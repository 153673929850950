@keyframes pulse {
    0% {
        transform: scale(1.0);
    }
    50% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1.0);
    }
}