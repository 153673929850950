.circle-progress {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 20vw;
    margin-top: 10vw;
    margin-bottom: 10vw;
    --animation-color: white;
    --animation-shadow-color: white;
}

.circle-progress:before,
.circle-progress:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    animation: pulseOut 1.8s ease-in-out infinite;
    filter: drop-shadow(0 0 1rem var(--animation-shadow-color));
}

.circle-progress:before {
    width: 100%;
    padding-bottom: 100%;
    box-shadow: inset 0 0 0 1rem var(--animation-color); /* Use the CSS variable */
    animation-name: pulseIn;
}

.circle-progress:after {
    width: calc(100% - 2rem);
    padding-bottom: calc(100% - 2rem);
    box-shadow: 0 0 0 0 var(--animation-color); /* Use the CSS variable */
}

@keyframes pulseIn {
    0% {
        box-shadow: inset 0 0 0 1rem var(--animation-color); /* Use the CSS variable */
        opacity: 1;
    }
    50%, 100% {
        box-shadow: inset 0 0 0 0 var(--animation-color); /* Use the CSS variable */
        opacity: 0;
    }
}

@keyframes pulseOut {
    0%, 50% {
        box-shadow: 0 0 0 0 var(--animation-color); /* Use the CSS variable */
        opacity: 0;
    }
    100% {
        box-shadow: 0 0 0 1rem var(--animation-color); /* Use the CSS variable */
        opacity: 1;
    }
}
