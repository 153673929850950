.entry-animation {
    animation: 0.35s ease-out 0s 1 growOnEntry;
}

@keyframes growOnEntry {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1.0);
    }
}